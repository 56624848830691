import { useEffect } from 'react';

import { useAppContext } from '../../components/providers/app-context';
import useLoggedUser from '../use-logged-user';

const usePageViewEvent = (name: string) => {
  const user = useLoggedUser();
  const { concent } = useAppContext();

  useEffect(() => {
    if (concent.markting && concent.statistics) {
      if (!window.location.href.includes('picker')) {
        window.dataLayer.push({
          event: 'pageView',
          pagePath: window.location.href,
          pageTitle: name,
          userID: user?.staff.id,
        });
      }
    }
  }, [concent]);

  useEffect(() => {
    if (user) {
      if (concent.markting && concent.statistics) {
        if (!window.location.href.includes('picker')) {
          window.dataLayer.push({
            event: 'dataLoad',
            userID: user.staff.id,
          });
        }
      }
    }
  }, [concent, user]);
};

export default usePageViewEvent;
